<template>
  <div>
    <the-app-bar />
    <healthhub-purchased-detail />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/footer.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
import HealthhubPurchasedDetail from "../../../components/PurchasedPack/healthhubPurchasedDetail.vue";

export default {
  components: { TheAppBar, Footer, HealthhubPurchasedDetail }
};
</script>

<style lang="scss" scoped></style>
