<template>
    <div class="font-montserrat top">
      <div class="row py-24 px-12 oonpay-bg">
        <div class="col-md-12">
          <h4 class="font-semibold  text-center text-oonpaytext text-3xl">
            {{ response.packageFullName }}
          </h4>
        </div>
      </div>
  
      <div v-if="loading" class="row py-5 justify-content-center">
        <fade-loader :loading="loading" :color="color"></fade-loader>
      </div>
      <!-- {{response.item1 && response.item1[0] }} -->
      <div v-else class="container-fluid  mt-5 p-5">
        <div class="row">
          <div class="container">
            <div class="row">
              <div class="col-md-6" v-if="response">
                <img
                  v-if="response.packageImages"
                  :src="
                  `${response.packageImages[0]}`
                  "
                  alt=""
                />
                <!-- TODO: Remove + from image url -->
                <img
                  v-else-if="response.merchantImageUrl"
                  v-lazy="
                  `${ImagebaseUrl}/oonpaymerchants/${response.merchantImageUrl}`
                  "
                  alt=""
                />
                <img
                  v-else
                  src="https://placehold.jp/1000x750.png
                    "
                  alt=""
                />
              </div>
              <div class="col-md-6">
                <h5>{{ response.packageFullName }}</h5>
                <p>
                  {{ response.packageDescription }}
                </p>
                <p>
                  Available Stock:
                  {{ response.packageQuantity }}
                </p>
                <p>
                  Package Unit Cost: {{ currency }}
                  {{ response.amount }}
                </p>
  
                <h6 class="text-info">
                  Beneficiary Info
                </h6>
                <p>Beneficiary Name: {{ response.beneficiaryFullName }}</p>
                <p>Beneficiary Phone: {{ response.beneficiaryPhoneNumber }}</p>
  
                <h6 class="text-info">
                  Merchant Info
                </h6>
                <p>Merchant Name: {{ response.merchantFullName }}</p>
                <p>Merchant Address: {{ response.merchantStreetAddress }}, {{ response.merchantRegion }}</p>
                <p>Merchant Phone: {{ response.merchantPhoneNumber }}</p>
                <div class="row" v-if="showusebtn">
                  <div class="col-md-6" >
                    <button
                      type="button"
                      class="btn btn-block btn-success"
                      @click.prevent="usePack(response.guid)"
                    >
                      Use Pack
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import FadeLoader from "vue-spinner/src/FadeLoader.vue";
  import { mapGetters } from 'vuex';
  import { ImagebaseUrl } from "../../config";

  
  export default {
    components: { FadeLoader },
  
    data() {
      return {
        ImagebaseUrl,
        id: this.$route.params.id,
        response: {},
        loading: false,
        currency: localStorage.getItem("clientCountryISO"),
        showusebtn: true,
      };
    },
  
    computed: {
      ...mapGetters("default", ["btnStatus"])
    },
  
    mounted() {
      this.$store.dispatch("healthhubclient/purchasePackDetail", this.id).then((res) => {
        this.response = res.data;
        this.showusebtn = JSON.parse(this.btnStatus) || JSON.parse(localStorage.getItem('showusebtn'))
       
      });
    },
  
    methods: {
      usePack(packageGUID) {
        this.$store
          .dispatch("healthhubclient/usePackage", packageGUID)
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data);
              this.$swal({
                icon: "success",
                title: "Success",
                text: res.data
              });
            }
          })
          .catch((err) => {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: err.response.data
            });
          });
      },
    }
  };
  </script>
  
  <style scoped>
  .oonpay-bg {
    background: #0c4369;
  }
  
  .other-img {
    width: 100%; /* You can set the dimensions to whatever you want */
    height: 200px;
    object-fit: cover;
  }
  
  .bg-warning {
    background: #e87c34 !important;
    color: aliceblue;
    font-weight: 500;
  }
  </style>
  